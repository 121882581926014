import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
// import { getSrc } from 'gatsby-plugin-image';

const CareerPostLink = ({ post }) => (
  // const featuredSrc = getSrc(post.frontmatter.featuredImage);

  <Link to={post.fields.urlPath}>
    <div className="w-full py-4 lg:flex">
      {/* <div */}
      {/*  className="h-56 lg:h-auto lg:w-64 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" */}
      {/*  style={{backgroundImage: `url(${featuredSrc})`, backgroundPosition: 'center center' }} title={post.frontmatter.title}> */}
      {/* </div> */}
      <div className="flex-1 bg-gray-200 p-7 rounded-b p-4 flex flex-col justify-between leading-normal lg:rounded-b-none lg:rounded-r">
        <div className="mb-8">
          <div className="text-black font-bold text-xl mb-2 text-left">
            {post.frontmatter.title}
          </div>
          <p className="text-black text-base text-left">{post.excerpt}</p>
        </div>
        <div className="flex flex-row">
          <div className="flex-1 text-sm">
            <p className="text-black">{post.frontmatter.date}</p>
          </div>
          <div className="flex-2 text-sm">
            <p className="text-white font-bold bg-kopirun-green rounded-md py-3 px-5 hover:bg-kopirun-green-bright focus:outline-none focus:ring-2 focus:ring-opacity-50">
              Apply Now
            </p>
          </div>
        </div>
      </div>
    </div>
  </Link>
);
CareerPostLink.propTypes = {
  post: PropTypes.shape({
    excerpt: PropTypes.string,
    fields: PropTypes.shape({
      urlPath: PropTypes.string
    }),
    frontmatter: PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      date: PropTypes.string
      // featuredImage: PropTypes.shape({
      //   childImageSharp: PropTypes.shape({
      //     gatsbyImageData: PropTypes.any
      //   })
      // })
    })
  }).isRequired
};

export default CareerPostLink;
