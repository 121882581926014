import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import CareerPostLink from 'components/CareerPostLink';
import Layout from 'components/Layout';
import SEO from 'components/Seo';

const CareersPage = ({
  data: {
    allMarkdownRemark: { edges }
  },
  location
}) => {
  const CareerPosts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <CareerPostLink key={edge.node.id} post={edge.node} />);
  return (
    <Layout>
      <SEO
        keywords={['kopirun', 'delivery', 'food delivery', 'crowdsource', 'contact us']}
        path={location.pathname}
        title="Careers"
      />
      <div className="max-w-7xl mx-auto">
        <section className="flex-1 px-4 mt-10 sm:px-6 lg:px-8">
          <h2 className="inline-block mb-4 text-2xl font-bold">Careers</h2>

          <div className="flex-1 items-center">{CareerPosts}</div>
        </section>
      </div>
    </Layout>
  );
};

CareersPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  }).isRequired
};

export default CareersPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            urlPath
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
